<template>
<v-container>
    <div>1й столбец - номенклатура wb, 2й - артикул поставщика, 3й - продажи, 4й - остаток</div>
    <v-file-input v-model="files" label="Выберите файл для импорта" accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></v-file-input>
    <v-btn class="primary" @click="submit">Отправить</v-btn>

    <v-overlay :value="overlay" style="z-index:1100">
        <div>
            <v-alert v-if="!errorMessage" type="success" >
                Успешно
            </v-alert>
            <v-alert v-else type="error" >
                {{errorMessage}}
            </v-alert>
        </div>
        <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>
    </v-overlay>

    <v-overlay :value="progress" style="z-index:1000">
        <div class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
                style="z-index:1001"
            ></v-progress-circular>
        </div>
    </v-overlay>
</v-container>
</template>

<script>
    // import axios from "axios";

    import axios from "axios";

    export default {
        data: () => ({
            files: null,
            overlay:false,
            progress: false,
            errorMessage: '',
        }),
        methods: {
            submit() {
                let formData = new FormData();
                formData.append("files", this.files, this.files.name);
                let $this = this;
                this.progress = true;
                axios({
                    url: '/api/wb/recommends',
                    method: 'POST',
                    responseType: 'blob',
                    data: formData
                }).then(function (response) {
                    if (!response.data.error) {
                        $this.progress = false;
                        let blob = new Blob([response.data], {type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(blob);
                        downloadLink.download =  'recommends.xlsx';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        URL.revokeObjectURL(downloadLink.href);
                        document.body.removeChild(downloadLink);
                    } else {
                        $this.errorMessage = response.data.error;
                        $this.overlay = true;
                        $this.progress = false;
                    }
                })
                    .catch(function () {
                        $this.errorMessage = 'Ошибка';
                        $this.overlay = true;
                        $this.progress = false;
                    });
            }
        },
        mounted() {

        }

    }
</script>
